import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const NEW_WAREHOUSE = 'NEW_WAREHOUSE';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const GET_WAREHOUSE = 'GET_WAREHOUSE';
export const GET_WAREHOUSES = 'GET_WAREHOUSES';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const DELETE_WAREHOUSES = 'DELETE_WAREHOUSES';

export function newWarehouse(warehouse) {
    const formData = new FormData();
    if(warehouse.photo && warehouse.photo != undefined)
    formData.append('photo', warehouse.photo);
    formData.append('warehousename', warehouse.warehousename);
    formData.append('warehousephone', warehouse.warehousephone);
    formData.append('warehouseIp', warehouse.warehouseIp);
    formData.append('managerfname', warehouse.managerfname);
    formData.append('managerlname', warehouse.managerlname);
    formData.append('manageremail', warehouse.manageremail);
    const request = axios.post(API_BASE_URL + '/api/management/warehouses', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: NEW_WAREHOUSE,
          payload: response.data
        })
        resolve();
      }).catch(err => reject(err.message));
    });
}

export function updateWarehouse(id, warehouse) {
    const formData = new FormData();
    if(warehouse.photo && warehouse.photo != undefined)
    formData.append('photo', warehouse.photo);
    formData.append('warehousename', warehouse.warehousename);
    formData.append('warehousephone', warehouse.warehousephone);
    formData.append('warehouseaddress', warehouse.warehouseaddress);
    formData.append('warehouseIp', warehouse.warehouseIp);
    formData.append('managerfname', warehouse.managerfname);
    formData.append('managerlname', warehouse.managerlname);
    formData.append('manageremail', warehouse.manageremail);
    const request = axios.put(API_BASE_URL + '/api/management/warehouses/'+id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: UPDATE_WAREHOUSE,
          payload: response.data
        })
        resolve();
      }).catch(err => reject(err.message));
    });
}

export function getWarehouse(id) {
    const request = axios.get(API_BASE_URL + '/api/management/warehouses/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: GET_WAREHOUSE,
          payload: response.data
        })
        resolve(response.data);
      }).catch(err => reject(err.message));
    });
}

export function getWarehouses() {
    const request = axios.get(API_BASE_URL + '/api/management/warehouses');
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: GET_WAREHOUSES,
          payload: response.data
        })
        resolve(response.data);  
      }).catch(err => reject(err.message));
    });
}

export function deleteWarehouse(id) {
    const request = axios.delete(API_BASE_URL + '/api/management/warehouses/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_WAREHOUSE,
          payload: id
        })
      resolve();
      }).catch(err => reject(err.message));
    });
}

export function deleteWarehouses() {
    const request = axios.delete(API_BASE_URL + '/api/management/warehouses');
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_WAREHOUSES,
        });
        resolve();
      }).catch(err => reject(err.message));
    });
}


export function resetManagerPassword(id) {
  const request = axios.post(API_BASE_URL + '/api/management/warehouses/resetManagerPassword/'+id);
  
  return (dispatch) => new Promise((resolve, reject) => {
    request.then((response) => {
      resolve();
    }).catch(err => reject(err.message));
  });
}
