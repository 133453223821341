import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_TASK = 'GET_TASK';
export const GET_TASKS = 'GET_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASKS = 'DELETE_TASKS';


export function getTask(id) {
    const request = axios.get(API_BASE_URL + '/api/management/tasks/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
            type: GET_TASK,
            payload: response.data
          })
          resolve();
        }
      ).catch(err => reject(err.message));
    });
}


export function getTasks() {
    const request = axios.get(API_BASE_URL + '/api/management/tasks');
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: GET_TASKS,
          payload: response.data
        })
        resolve(response.data);  
      }).catch(err => reject(err.message));
    });
}

export function getTasksByCounter(id) {
  const request =  axios.get(API_BASE_URL + '/api/management/tasks/counter/'+id);

  return (dispatch) => new Promise((resolve, reject) => {
    request.then((response) => {
      resolve(response.data);  
    }).catch(err => reject(err.message));
  });
}


export function getTaskByWorker(id) {
  const request =  axios.get(API_BASE_URL + '/api/management/tasks/worker/'+id);

  return () => new Promise((resolve, reject) => {
    request.then((response) => {
      resolve(response.data);  
    }).catch(err => reject(err.message));
  });
}


export function getTaskByWarehouse(id) {
  const request =  axios.get(API_BASE_URL + '/api/management/tasks/warehouse/'+id);

  return () => new Promise((resolve, reject) => {
    request.then((response) => {
      resolve(response.data);  
    }).catch(err => reject(err.message));
  });
}

export function deleteTask(id) {
    const request = axios.delete(API_BASE_URL + '/api/management/tasks/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_TASK,
          payload: id
        });
        resolve();
      }).catch(err => reject(err.message));
    });
}

export function deleteTasks() {
    const request = axios.delete(API_BASE_URL + '/api/management/tasks');
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_TASKS,
        })
        resolve();
      }).catch(err => reject(err.message));
    });
}
