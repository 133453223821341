/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  NEW_WAREHOUSE,
  UPDATE_WAREHOUSE,
  GET_WAREHOUSE,
  GET_WAREHOUSES,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSES
} from 'src/actions/warehouseAction';

const initialState = {
  warehouses: [],
  warehouse: null
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_WAREHOUSE: {
        const { warehouse } = action.payload;

        return produce(state, (draft) => {
          draft.warehouses.push(warehouse);
        });
    }

    case UPDATE_WAREHOUSE: {
      const { warehouse } = action.payload;

      return produce(state, (draft) => {
        draft.warehouses.map(_warehouse => {
            if(_warehouse._id ===  warehouse._id)
                return warehouse;
            return _warehouse;
        });
      });
    }

    case GET_WAREHOUSE: {
      const { warehouse } = action.payload;

      return produce(state, (draft) => {
        draft.warehouse = warehouse;
      });
    }

    case GET_WAREHOUSES: {
      const { warehouses } = action.payload;

      return produce(state, (draft) => {
        draft.warehouses = warehouses;
      });
    }

    case DELETE_WAREHOUSE: {
      const id = action.payload;
      return produce(state, (draft) => {
        draft.warehouses = draft.warehouses.filter(warehouse => warehouse._id !== id)
        draft.warehouse = null;
      });
    }

    case DELETE_WAREHOUSES: {
      return produce(state, (draft) => {
        draft.warehouses = [];
        draft.warehouse = null;
      });
    }
    default: {
      return state;
    }
  }
};

export default warehouseReducer;
