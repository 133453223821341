/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_TASK,
  GET_TASKS,
  DELETE_TASK,
  DELETE_TASKS,
} from 'src/actions/taskAction';

const initialState = {
  tasks: [],
  task: null
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_TASK: {
      const { task } = action.payload;

      return produce(state, (draft) => {
        draft.task = task;
      });
    }

    case GET_TASKS: {
      const { tasks } = action.payload;

      return produce(state, (draft) => {
        draft.tasks = tasks;
      });
    }

    case DELETE_TASK: {
      const id = action.payload;
      return produce(state, (draft) => {
        draft.tasks = draft.tasks.filter(task => task._id !== id)
        draft.task = null;
      });
    }

    case DELETE_TASKS: {
      return produce(state, (draft) => {
        draft.tasks = [];
        draft.task = null;
      });
    }
    default: {
      return state;
    }
  }
};

export default taskReducer;
