import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import workerReducer from './workerReducer';
import warehouseReducer from './wareHouseReducer';
import counterReducer from './counterReducer';
import taskReducer from './taskReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  worker: workerReducer,
  counter: counterReducer,
  task: taskReducer,
  warehouse: warehouseReducer,
  form: formReducer
});

export default rootReducer;
