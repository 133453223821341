/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { useSelector } from 'react-redux';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        perm: 'admin manager',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        perm: 'admin manager',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        perm: 'admin',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        perm: 'manager',
        component: lazy(() => import('src/views/reports/DashboardOfManagerview'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard/:warehouseId',
        perm: 'admin',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        perm: 'admin manager',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/workers',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/WorkerListView'))
      },
      {
        exact: true,
        path: '/app/management/workers/new',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/WorkerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/workers/:workerId',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/WorkerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/workers/:workerId/edit',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/WorkerEditView'))
      },
      {
        exact: true,
        path: '/app/management/counters',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/CounterListView'))
      },
      {
        exact: true,
        path: '/app/management/counters/new',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/CounterCreateView'))
      },
      {
        exact: true,
        path: '/app/management/counters/:counterId',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/CounterDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/counters/:counterId/edit',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/CounterEditView'))
      },
      {
        exact: true,
        path: '/app/management/warehouses',
        perm: 'admin',
        component: lazy(() => import('src/views/management/WarehouseListView'))
      },
      {
        exact: true,
        path: '/app/management/warehouses/new',
        perm: 'admin',
        component: lazy(() => import('src/views/management/WarehouseCreateView'))
      },
      {
        exact: true,
        path: '/app/management/warehouses/:warehouseId',
        perm: 'admin',
        component: lazy(() => import('src/views/management/WarehouseDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/warehouses/:warehouseId',
        perm: 'manager',
        component: lazy(() => import('src/views/management/WarehouseDetailsView2'))
      },
      {
        exact: true,
        path: '/app/management/warehouses/:warehouseId/edit',
        perm: 'admin',
        component: lazy(() => import('src/views/management/WarehouseEditView'))
      },
      {
        exact: true,
        path: '/app/management/tasks',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/TaskListView'))
      },
      {
        exact: true,
        path: '/app/management/tasks/:taskId',
        perm: 'admin manager',
        component: lazy(() => import('src/views/management/TaskDetailsView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // {
  //   path: '/docs',
  //   layout: DocsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/docs',
  //       component: () => <Redirect to="/docs/welcome" />
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/welcome',
  //       component: lazy(() => import('src/views/docs/WelcomeView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/getting-started',
  //       component: lazy(() => import('src/views/docs/GettingStartedView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/environment-variables',
  //       component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/deployment',
  //       component: lazy(() => import('src/views/docs/DeploymentView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/api-calls',
  //       component: lazy(() => import('src/views/docs/ApiCallsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/analytics',
  //       component: lazy(() => import('src/views/docs/AnalyticsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/authentication',
  //       component: lazy(() => import('src/views/docs/AuthenticationView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/routing',
  //       component: lazy(() => import('src/views/docs/RoutingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/settings',
  //       component: lazy(() => import('src/views/docs/SettingsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/state-management',
  //       component: lazy(() => import('src/views/docs/StateManagementView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/theming',
  //       component: lazy(() => import('src/views/docs/ThemingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/support',
  //       component: lazy(() => import('src/views/docs/SupportView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/changelog',
  //       component: lazy(() => import('src/views/docs/ChangelogView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes, perm) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        if(route.perm && !route.perm.includes(perm))
          return null;

        if(route.perm)
          console.log(route.perm, perm)
        
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes, perm)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {

  const { user } = useSelector((state) => state.account);
  
  return renderRoutes(routesConfig, user?user.role:'user');
}

export default Routes;
