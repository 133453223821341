/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  NEW_WORKER,
  UPDATE_WORKER,
  GET_WORKER,
  GET_WORKERS,
  DELETE_WORKER,
  DELETE_WORKERS
} from 'src/actions/workerAction';

const initialState = {
  workers: [],
  worker: null
};

const workerReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_WORKER: {
        const { worker } = action.payload;

        return produce(state, (draft) => {
          draft.workers.push(worker);
        });
    }

    case UPDATE_WORKER: {
      const { worker } = action.payload;

      return produce(state, (draft) => {
        draft.workers.map(_worker => {
            if(_worker._id ===  worker._id)
                return worker;
            return _worker;
        });
        draft.worker = worker;
      });
    }

    case GET_WORKER: {
      const { worker } = action.payload;

      return produce(state, (draft) => {
        draft.worker = worker;
      });
    }

    case GET_WORKERS: {
      const { workers } = action.payload;

      return produce(state, (draft) => {
        draft.workers = workers;
      });
    }

    case DELETE_WORKER: {
      const id = action.payload;
      return produce(state, (draft) => {
        draft.workers = draft.workers.filter(worker => worker._id !== id);
        draft.worker = null;
      });
    }

    case DELETE_WORKERS: {
      return produce(state, (draft) => {
        draft.workers = [];
        draft.worker = null;
      });
    }
    default: {
      return state;
    }
  }
};

export default workerReducer;
