import { reject } from 'lodash';
import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const NEW_WORKER = 'NEW_WORKER';
export const UPDATE_WORKER = 'UPDATE_WORKER';
export const GET_WORKER = 'GET_WORKER';
export const GET_WORKERS = 'GET_WORKERS';
export const DELETE_WORKER = 'DELETE_WORKER';
export const DELETE_WORKERS = 'DELETE_WORKERS';

export function newWorker(worker) {
    const formData = new FormData();
    if(worker.photo && worker.photo != undefined)
    formData.append('photo', worker.photo);
    formData.append('name', worker.name);
    formData.append('phone', worker.phone);
    formData.append('warehouse', worker.warehouse);
    const request = axios.post(API_BASE_URL + '/api/management/workers', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data.worker) {
          dispatch({
            type: NEW_WORKER,
            payload: response.data
          })
          resolve(response.data.worker);
        } else {
          reject(response.data.error);
        }
    })
    });
}

export function updateWorker(id, worker) {
    const formData = new FormData();
    if(worker.photo && worker.photo != undefined)
    formData.append('photo', worker.photo);
    formData.append('name', worker.name);
    formData.append('phone', worker.phone);
    formData.append('warehouse', worker.warehouse);
    const request = axios.put(API_BASE_URL + '/api/management/workers/'+id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        if (response.data.worker) {
          dispatch({
            type: UPDATE_WORKER,
            payload: response.data
          })
          resolve(response.data.worker);
        } else {
          reject(response.data.error);
        }
        resolve(response.data)
        }).catch(err => reject(err.message))
    });
}

export function getWorker(id) {
    const request = axios.get(API_BASE_URL + '/api/management/workers/'+id);
  
    return (dispatch) => new Promise((resolve,reject) => {
      request.then((response) => {
        dispatch({
          type: GET_WORKER,
          payload: response.data
        })
        resolve(response.data);
      }).catch(err=>reject(err.message));
    });
}

export function getWorkers() {
    const request = axios.get(API_BASE_URL + '/api/management/workers');
  
    return (dispatch) => new Promise((resolve,reject) => {
      request.then((response) => {
        dispatch({
          type: GET_WORKERS,
          payload: response.data
        })
        resolve(response.data);
      }).catch(err=>reject(err.message));
    });
}

export function deleteWorker(id) {
    const request = axios.delete(API_BASE_URL + '/api/management/workers/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_WORKER,
          payload: id
        })
        resolve();
      }).catch(err => reject(err.message));
    });
}

export function deleteWorkers() {
  const request = axios.delete(API_BASE_URL + '/api/management/workers');

  return (dispatch) => new Promise((resolve, reject) => {
    request.then((response) => {
      dispatch({
        type: DELETE_WORKERS,
      })
      resolve();
    }).catch(err => reject(err.message));
  });
}

export function resetWorkerPassword(id) {
  const request = axios.post(API_BASE_URL + '/api/management/workers/resetPassword/'+id);

  return (dispatch) => new Promise((resolve, reject) => {
    request.then((response) => {
      resolve();
    }).catch(err => reject(err.message));
  });
}
