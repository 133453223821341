import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const NEW_COUNTER = 'NEW_COUNTER';
export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export const GET_COUNTER = 'GET_COUNTER';
export const GET_COUNTERS = 'GET_COUNTERS';
export const DELETE_COUNTER = 'DELETE_COUNTER';
export const DELETE_COUNTERS = 'DELETE_COUNTERS';

export function newCounter(counter) {
    const formData = new FormData();
    if(counter.photo && counter.photo !== undefined)
    formData.append('photo', counter.photo);
    formData.append('name', counter.name);
    formData.append('phone', counter.phone);
    formData.append('mac', counter.mac);
    formData.append('warehouse', counter.warehouse);
    const request = axios.post(API_BASE_URL + '/api/management/counters', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: NEW_COUNTER,
          payload: response.data
        })
        resolve();
    }).catch(err => reject(err.message));
    })
}

export function updateCounter(id, counter) {
    const formData = new FormData();
    if(counter.photo && counter.photo !== undefined)
    formData.append('photo', counter.photo);  
    formData.append('name', counter.name);
    formData.append('phone', counter.phone);
    formData.append('mac', counter.mac);
    formData.append('warehouse', counter.warehouse);
    const request = axios.put(API_BASE_URL + '/api/management/counters/'+id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: UPDATE_COUNTER,
          payload: response.data
        })
        resolve();
      }).catch(err => reject(err.message))
    });
}

export function getCounter(id) {
    const request = axios.get(API_BASE_URL + '/api/management/counters/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: GET_COUNTER,
          payload: response.data
        })
        resolve(response.data);
      }).catch(err => reject(err.message));
    });
}

export function getCounters() {
    const request = axios.get(API_BASE_URL + '/api/management/counters');
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) => {
        dispatch({
          type: GET_COUNTERS,
          payload: response.data
        })
        resolve(response.data);
      }).catch(err => reject(err.message));
    });
}

export function deleteCounter(id) {
    const request = axios.delete(API_BASE_URL + '/api/management/counters/'+id);
  
    return (dispatch) => new Promise((resolve, reject) => {
      request.then((response) =>{ 
        dispatch({
          type: DELETE_COUNTER,
          payload: id
        })
        resolve();
      }).catch(err => reject(err.message));
    });
}

export function deleteCounters() {
    const request = axios.delete(API_BASE_URL + '/api/management/counters');
  
    return (dispatch) => new Promise((reslove, reject) => {
      request.then((response) => {
        dispatch({
          type: DELETE_COUNTERS,
        })
        reslove();
      }).catch(err =>reject(err.message));
    });
}
